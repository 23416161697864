<template>
    <div class="content">
        <headerText></headerText>
        <div class="photo_img"><p>关于我们</p></div>
        <div style="margin: 0 auto; width: 80%;padding: 20px 0px;">
            <h1 style="text-align: center">最美课本百科</h1>
            <p>最美课本是北京再佳学教育科技有限公司旗下产品，定位为美育融合化学习平台。</p>
            <p style="margin-top:10px">中文名：最美课本
                <br>
                开发商：北京再佳学教育科技有限公司
                <br>
                分 类：美育教育
                <br>
                APP支持版本：安卓、iOS</p>
            <h5 style="margin-top:10px">简介：</h5>
            <p style="margin-top:10px">最美课本是美育融合化学习平台。历时五年对基础进行二次开发，将传统艺术、现代艺术、非遗、儿童舞台剧等近两百种艺术表现形式融入，让知识活起来,提高孩子学习兴趣。</p>
            <h5 style="margin-top:10px">产品功能：</h5>
            <h5 style="margin-top:10px">学生端：</h5>
            <p style="margin-top:10px">通过美育视频课程学习、AI题库，跟读测评，知识百科，成长树，趣配音，学习记录等功能，实现课前预习、课中学习、课后练习一体化；</p>
            <h5 style="margin-top:10px">家长端：</h5>
            <p style="margin-top:10px">监督孩子学习，通过学情分析报告了解孩子各科内容掌握情况，无论在哪里，可以随时随地陪伴孩子学习；精准发现孩子学习问题，及时给与方向性提升指导；</p>
            <h5 style="margin-top:10px">产品优势：</h5>
            <p style="margin-top:10px">
                1、 美育融合：融入200多种艺术表现形式，美育教  学使人向善、向上；
                <br>
                2、 应景学习：视频上的文字、画  面、声音同步展现，可以激发孩子的脑力思维；
                <br>
                3、 双语配音：中文由央视少儿频道配音员配音，英文由美国迪士尼影视配音演员配音；
                <br>
                4、 人工智能：大数据智能分析，制定个性化学习方案；
                <br>
            <h5 style="margin-top:10px">为学生带来的核心价值：</h5>
            <p style="margin-top:10px">
                1、提升学习兴趣；
                <br>
                2、提高阅读能力；
                <br>
                3、激发脑力思维；
                <br>
                4、增强知识记忆；
                <br>
                5、树立人格自信。</p>
        </div>

        <!-- 底部 -->
        <footerText></footerText>
    </div>
</template>

<script>

    export default {
        data() {
            return {

            };
        },
        mounted() {
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/aboutEn')
            }
        },
        methods: {

        }
    };
</script>

<style scoped lang="scss">
    @import '../../assets/css/about.scss';
</style>
